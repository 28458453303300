import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import { Divider, Button, Form, Input, InputNumber, Space } from "antd";
import { CSVLink } from "react-csv";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ToolPage = () => {
  const [wattHours, setWattHours] = useState({ [0]: 0 });
  const [totalWattHours, setTotalWattHours] = useState("");
  const [csvData, setCsvData] = useState(["empty", "csv"]);

  const onValChange = (changedValues, allValues) => {
    let rows = [];
    const apps = allValues.appliances;
    for (let row = 0; row < apps.length; row++) {
      const app = apps[row];
      if (app && app.hours && app.watts) {
        const wh = app.watts * app.hours;
        rows[row] = wh;
        apps[row]["WH"] = wh;
      }
    }
    setTotalWattHours("");
    setCsvData({ apps });
    setWattHours(rows);
  };

  const calculateWattHours = () => {
    // calculate watt hours
    let whTotal = 0;
    for (let i = 0; i < wattHours.length; i++) {
      whTotal = whTotal + wattHours[i];
    }
    setTotalWattHours(
      <div>
        <h2>Total daily watt hours: {whTotal}</h2>
        <CsvButton />
      </div>
    );
  };

  const CsvButton = () => {
    if (typeof csvData.apps != "undefined") {
      console.log(typeof csvData.apps);
      return (
        <CSVLink
          data={csvData.apps}
          filename={"holobiontLoadCalc.csv"}
          target="_blank"
        >
          Download this as a CSV file
        </CSVLink>
      );
    } else {
      console.log(csvData);
      return <p>no data?</p>;
    }
  };

  return (
    <Layout>
      <div className="index-container">
        <Helmet title={config.siteTitle} />
        <SEO />
        <h1>Solar Tools</h1>

        <Divider />

        <h3> Load calculator</h3>
        <Form
          name="dynamic_form_nest_item"
          onFinish={calculateWattHours}
          onValuesChange={onValChange}
          autoComplete="off"
        >
          <Form.List name="appliances">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "appliance"]}
                      rules={[
                        { required: true, message: "Missing appliance name" },
                      ]}
                    >
                      <Input placeholder="Appliance name" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "watts"]}
                      rules={[{ required: true, message: "Watts" }]}
                    >
                      <InputNumber placeholder="Watts" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "hours"]}
                      rules={[{ required: true, message: "Missing hours" }]}
                    >
                      <InputNumber placeholder="Hours on per day" max={24} />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      //   name={[name, "watthours"]}
                      rules={[{ message: "watt hours" }]}
                      value={wattHours[name]}
                      shouldUpdate
                    >
                      {() => {
                        return <span>{wattHours[name]} </span>;
                      }}
                    </Form.Item>
                    <span>Watt Hours</span>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add appliance
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Calculate Watt hours
            </Button>
          </Form.Item>
        </Form>
        {totalWattHours}
      </div>
    </Layout>
  );
};

export default ToolPage;
